import React, { useState } from 'react'
import Radiobutton from '../components/radiobutton'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

const Label = styled.div`
  font-family: ${props => props.theme.fonts.primary};
  margin: 0 110px 29px 0;
  flex-grow: 0;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
`

function Selection(props) {
  const [selectedItem, setSelectedItem] = useState(0)

  return (
    <>
      <Label>
        <FormattedMessage id="unsubscribe_reason_label" />
      </Label>
      {props.items.map(item => (
        <Radiobutton
          onCheckboxClick={() =>
            setSelectedItem(item.id) || props.onItemSelected(item.id)
          }
          checked={selectedItem === item.id}
          rounded={true}
        >
          <FormattedMessage id={item.value} />
        </Radiobutton>
      ))}
    </>
  )
}

export default Selection
