import React from 'react'
import styled from 'styled-components'

import checkboxChecked from '../images/Checkbox_checked_v2.svg'
import checkboxUnchecked from '../images/Checkbox_unchecked_v2.svg'

const CheckboxWrapper = styled.div`
  margin: 30px auto;
  cursor: pointer;
  text-align: left;
`

const Label = styled.label`
  display: flex;
  align-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 0.5px;
`
const Checkbox = ({ checked, onCheckboxClick, children }) => (
  <CheckboxWrapper onClick={onCheckboxClick}>
    <Label>
        {checked ? (
          <img src={checkboxChecked} alt="checkbox checked" />
        ) : (
          <img src={checkboxUnchecked} alt="checkbox unchecked" />
        )}
      {children}
    </Label>
  </CheckboxWrapper>
)

export default Checkbox
