import React from 'react'
import styled from 'styled-components'

import radioActive from '../images/Radiobutton_active_v2.svg'
import radioInactive from '../images/Radiobutton_inactive_v2.svg'

const CheckboxWrapper = styled.div`
  margin: 20px auto;
  cursor: pointer;
  text-align: left;
`

const Label = styled.label`
  display: flex;
  gap: 10px;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 0.5px;
  align-items: flex-start;

  > div {
    display: inline-block;
    height: 100%;
    > img {
      vertical-align: middle;
      width: 17.1px;
      height: 16px;
    }
  }
`

const Radiobutton = ({ checked, onCheckboxClick, children }) => (
  <CheckboxWrapper onClick={onCheckboxClick}>
    <Label>
      <div>
        {checked ? (
          <img src={radioActive} alt="radio checked" />
        ) : (
          <img src={radioInactive} alt="radio unchecked" />
        )}
      </div>
      <div>{children}</div>
    </Label>
  </CheckboxWrapper>
)

export default Radiobutton
