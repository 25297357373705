import React from 'react'
import styled from 'styled-components'

const ButtonElement = styled.button`
  width: 290px;
  height: 50px;
  padding: 10px 35px;
  letter-spacing: 1px;
  text-align: center;
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  margin-left: auto;
  margin-right: auto;
  outline: none;
  border: 0 none;
  font-size: 16px;
  border-radius: 2px;
  cursor: pointer;
  font-family: ${props => props.theme.fonts.primary};
  :disabled {
    background-color: ${props => props.theme.colors.lightGray};
    color: ${props => props.theme.colors.darkGray};
    cursor: default;
  }

  :hover:not(:disabled) {
    text-decoration: none;
    background-color: ${props => props.theme.colors.darkGray};
    color: #fff;
  }
`

const Button = ({ children, ...rest }) => (
  <ButtonElement {...rest}>{children}</ButtonElement>
)

export default Button
