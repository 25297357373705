import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'
import Layout from '../components/layout'
import Radiobutton from '../components/checkbox'
import Button from '../components/button'
import { getParameterByName } from '../utils/querystring'
import Selection from '../components/selection'

const PageWrapper = styled.div`
  max-width: ${props => props.theme.breakpoints.desktop}px;
  margin: 0 auto;
  text-align: center;

  > button {
    margin-top: 40px;
  }
`

const Center = styled.div`
  max-width: 400px;
`

const BoldParagraph = styled.p`
  font-family: ${props => props.theme.fonts.logo};
  font-size: 24px;
  margin: 10px auto 45px;
  text-align: center;
  line-height: 32px;
`

const LightParagraph = styled.p`
  margin: 10px auto 45px;
  text-align: center;
`

const Input = styled.textarea`
  margin: 10px auto 45px;
  width: 100%;
  height: 100px;
  resize: none;
`

const MainSelection = styled.div`
  max-width: 290px;
  margin-left: auto;
  margin-right: auto;
`

const unsubscribeReasons = [
  { id: 1, value: 'unsub1' },
  { id: 2, value: 'unsub2' },
  { id: 3, value: 'unsub3' },
  { id: 4, value: 'unsub4' },
  { id: 5, value: 'unsub5' },
  { id: 6, value: 'unsub6' },
]

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    const recipientId = getParameterByName('recipientid')
    this.state = {
      checked: null,
      submitted: false,
      recipientId,
      unsubscribeReasonId: 0,
    }
  }

  handleUnsubscribeRequest = status => {
    this.setState({ submitted: true })
  }

  track = checked => {
    if (checked === true) {
      ewt.track({ name: 'marketingConsentFormOptOut', type: 'customformoptout' })
    } else if (checked === false) {
      ewt.track({ name: 'marketingConsentFormOptIn', type: 'customformoptout' })
    }
  }

  setCheckbox = value => {
    this.setState({ checked: value })
    if (!value){
      this.setState({unsubscribeReasonId: 0})
    }
  }

  onButtonClick = () => {
    const {
      recipientId,
      checked,
      unsubscribeReasonId,
      reasonAdditionalInfo,
    } = this.state

    axios
      .post('/unsubscribe', {
        recipientId,
        unsubscribeFlag: checked,
        reasonId: checked ? unsubscribeReasonId : 0,
        reasonAdditionalInfo: reasonAdditionalInfo,
      })
      .then(this.handleUnsubscribeRequest)
      .then(() => this.track(checked))
      .catch(() => {
        navigate(`/500${window.location.search}`)
      })
  }

  onReasonSelected = value => {
    this.setState({ unsubscribeReasonId: value })
  }

  renderForm = checked => (
    <>
      <Center>
        <MainSelection>
          <Radiobutton
            checked={checked === true}
            onCheckboxClick={() => this.setCheckbox(true)}
          >
            <FormattedMessage id="unsubscribe_label" />
          </Radiobutton>
          <Radiobutton
            checked={checked === false}
            onCheckboxClick={() => this.setCheckbox(false)}
          >
            <FormattedMessage id="subscribe_label" />
          </Radiobutton>
        </MainSelection>
        {checked && (
          <Selection
            items={unsubscribeReasons}
            onItemSelected={this.onReasonSelected}
          ></Selection>
        )}
        {checked && this.state.unsubscribeReasonId === 6 && (
          <Input
            type="text"
            value={this.state.reasonAdditionalInfo}
            onChange={e =>
              this.setState({ reasonAdditionalInfo: e.target.value })
            }
          ></Input>
        )}
      </Center>
      <Button disabled={checked === null} onClick={this.onButtonClick}>
        <FormattedMessage id="confirm_label" />
      </Button>
    </>
  )

  renderConfirmation = checked => (
    <>
      <BoldParagraph>
        <FormattedMessage id={checked ? 'cancel_confirmation' : 'thank_you'} />
      </BoldParagraph>
      <LightParagraph>
        <FormattedMessage
          id={checked ? 'so_long_label' : 'subscription_confirmation'}
        />
      </LightParagraph>
    </>
  )

  render() {
    const { checked, submitted } = this.state

    return (
      <Layout>
        <PageWrapper>
          {submitted
            ? this.renderConfirmation(checked)
            : this.renderForm(checked)}
        </PageWrapper>
      </Layout>
    )
  }
}

export default IndexPage
